import React from "react"
import { Helmet } from "react-helmet"
import KdpHeader from "../../blocs/Header/Header"
import KdpFooter from "../../blocs/Footer/Footer"
import KdpAriane from "../../blocs/Ariane"
import KdpCgu from "../../blocs/Cgu"

const ariane = [
  { name: 'CGU', href: '#', current: true },
]

export default function Cgu() {
  return (
    <div className="bg-white">
      <Helmet>
        <title>Conditions Générales Utilisation CEOS Avocats</title>
        <meta name="description" content="Retrouvez les Conditions d’Utilisation (CGU) de CEOS Avocats" />
      </Helmet>
      <header>
        <KdpHeader />
      </header>
      <main>
        <KdpAriane 
          filariane={ariane}
        />
        


        <KdpCgu />




      </main>
      <footer>
      <KdpFooter />
      </footer>
    </div>
  );
}