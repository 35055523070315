import React from 'react'
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/solid'

export default function Ariane(props) {

    const { filariane } = props
    
    return (
      <nav className="flex max-w-7xl mx-auto py-4 px-4 sm:py-4 sm:px-6 lg:px-8" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-4">
          <li>
            <div>
              <a href="/" className="text-gray-400 hover:text-gray-500">
                <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                <span className="sr-only">Accueil</span>
              </a>
            </div>
          </li>
          {filariane.map((page) => (
            <li key={page.name}>
              <div className="flex items-center">
                <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                <a
                  href={page.href}
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  aria-current={page.current ? 'page' : undefined}
                >
                  {page.name}
                </a>
              </div>
            </li>
          ))}
        </ol>
      </nav>
    )
  }
